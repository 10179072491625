import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import Item from "../components/Item"
import Pagination from "../components/Pagination"

export default ({
  data: {
    allMarkdownRemark: { totalCount, edges: contents },
  },
  pageContext,
}) => (
  <Layout page={"top"} frontmatter={null}>
    <div className="uk-section-small uk-padding-remove-top">
      {contents.map(
        ({
          node: {
            id,
            frontmatter: { title, date, description, tags, thumbnail },
            fields: { slug },
            excerpt,
          },
        }) => (
          <Item
            key={`item_${id}`}
            date={date}
            slug={slug}
            title={title}
            description={description}
            tags={tags}
            thumbnail={thumbnail.childImageSharp.fluid}
          />
        )
      )}
    </div>

    <div>
      <Pagination pageContext={pageContext} baseUrl={"page"} />
    </div>
  </Layout>
)

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY年MM月DD日")
            tags
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 820) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
