import React from "react"
import { Link } from "gatsby"
import styles from "../styles/modules/item.module.sass"
import Image from "gatsby-image"
import Tag from "../components/Tag"

export default ({ date, slug, title, description, tags, thumbnail }) => {
  return (
    <>
      <div className="slipnote-section">
        <span className="slipnote-text-meta">{date}</span>
        <h2 className={styles.title}>
          <Link to={slug}>{title}</Link>
        </h2>
        {thumbnail && <Image fluid={thumbnail} />}
        <div className={styles.tags}>
          {tags.length && tags.map(tag => <Tag key={tag} tag={tag} />)}
        </div>
        <p className={styles.excerpt}>{description}</p>
      </div>
    </>
  )
}
